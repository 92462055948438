@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-Black.otf) format(woff);
	font-style: normal;
	font-weight: 900;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-BlackItalic.otf) format(woff);
	font-style: italic;
	font-weight: 900;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-ExtraBold.otf) format(woff);
	font-style: normal;
	font-weight: 800;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-ExtraBoldItalic.otf) format(woff);
	font-style: italic;
	font-weight: 800;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-Bold.otf) format(woff);
	font-style: normal;
	font-weight: 700;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-BoldItalic.otf) format(woff);
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: "MazzardH", sans-serif;
	src: url(./assets/fonts/MazzardH-ExtraLight.otf) format(woff);
	font-style: normal;
	font-weight: 400;
}

:root {
	--title: #35333a;
	--text: #838397;
	--body: #ffffff;
	--section: #f5f8ff;
	--white: #ffffff;
	// --base: #f8bc6a;
	--base: #24e9c4;
	// --dark: #292194;
	--dark: #1a1287;
	--body-fonts: "MazzardH", sans-serif;
	--heading-fonts: "MazzardH", sans-serif;
	--border: #d7dce6;
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text);
	font-size: 16px;
	line-height: 32px;
	font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 42px;
}
h2 {
	font-size: 34px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
	padding-left: 35px;
	padding-right: 35px;
	@media (width < 375px) {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@include breakpoint(xl) {
	.container {
		max-width: 1140px;
	}
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}
.cmn-btn {
	color: var(--title);
	padding: 15px 22px;
	display: flex;
	gap: 6px;
	align-items: center;
	display: inline-block;
	font-size: 14px;
	line-height: 21px;
	text-transform: capitalize;
	border-radius: 5px;
	border: none;
	outline: none;
	box-shadow: none;
	transition: all ease 0.3s;
	text-align: center;
	border: 1px solid var(--border);
	&:hover {
		background: var(--white);
		color: var(--base);
		border-color: var(--base);
	}
	@include breakpoint(max-sm) {
		padding: 10px 18px;
		font-weight: 600;
	}
}
.header-section {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	padding: 20px 0;
	z-index: 999;
	&.fixed {
		background: var(--dark);
	}
}
.header-section {
	&.active {
		background: var(--dark);
	}
}
.header-section,
.banner-section {
	.container {
		padding-left: 35px;
		padding-right: 35px;
		@media (width < 375px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	@include breakpoint(xl) {
		.container {
			max-width: 1376px;
			padding-inline-start: 114px;
		}
	}
}
@include breakpoint(xl) {
	.header-section {
		// padding-right: 35px;
		&:not(.fixed) {
			.container {
				padding-inline-start: 114px;
			}
		}
	}
}
.header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.logo {
		display: block;
		max-width: 226px;
		img {
			width: 100%;
		}
	}
	@include breakpoint(max-lg) {
		.logo {
			max-width: 126px;
		}
	}
}
.header-menu {
	.menu {
		display: flex;
		align-items: center;
		gap: 23px;
		li {
			a {
				display: inline-block;
				color: var(--white);
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				&:not(.cmn-btn) {
					padding: 10px;
				}
				&.cmn-btn {
					min-width: 185px;
					&:hover {
						color: var(--dark);
						border-color: var(--dark);
					}
				}
			}
		}
	}
	@include breakpoint(max-lg) {
		width: 100%;
		height: calc(100vh - 53px);
		left: 0;
		top: 53px;
		position: fixed;
		background: var(--dark);
		z-index: 999;
		justify-content: center;
		transition: all ease 0.4s;
		overflow-y: auto;
		// padding-top: 90px;
		&:not(.active) {
			opacity: 0;
			visibility: hidden;
			left: 0;
			transform-origin: left;
			transform: translateX(-50px);
		}
		.menu {
			flex-direction: column;
			gap: 30px;
			justify-content: center;
			li {
				a {
					&:not(.cmn-btn) {
						padding: 10px;
					}
				}
			}
		}
		// gap: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
}
.social-follow {
	.follow {
		color: var(--white);
		font-weight: 400;
		font-size: 40px;
		line-height: 47px;
		margin-bottom: 9px;
		margin-top: 20px;
	}
}
.social-icons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 14px;
}
.text-base {
	color: var(--base);
}
.banner-section {
	background: var(--dark);
	padding: 219px 0 200px;
	position: relative;
	.container {
		position: relative;
		z-index: 9;
	}
	.title {
		font-weight: 400;
		font-size: 73.9456px;
		line-height: 80px;
		margin-bottom: 27px;
		text-transform: capitalize;
		span {
			display: block;
		}
	}
	.cmn-btn {
		min-width: 185px;
		color: var(--white);
		&:hover {
			color: var(--dark);
			border-color: var(--dark);
		}
	}
	@include breakpoint(max-lg) {
		padding-top: 180px;
	}
	@include breakpoint(max-md) {
		// padding-top: 157px;
		padding-top: 147px;
		margin-top: 0;
		padding-bottom: 52px;
	}
}
.banner-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@include breakpoint(md) {
		.social-icons {
			flex-direction: column;
			margin-top: 30px;
		}
		.arrow-down {
			position: absolute;
			bottom: -180px;
			left: 0px;
			margin: 0;
		}
	}
	.banner-content {
		width: 0;
		flex-grow: 1;
		@include breakpoint(lg) {
			max-width: 680px;
		}
	}
	@include breakpoint(max-md) {
		flex-wrap: wrap;
		.banner-content {
			width: 100%;
			text-align: center;
			.title {
				// font-size: 55px;
				font-size: 50px;
				line-height: 55px;
				margin-bottom: 50px;
			}
		}
		.banner-right {
			margin: 0 0 50px;
			text-align: center;
			&-text {
				width: 100%;
				border-radius: 20px;
				min-height: 0;
			}
		}
		.social-icons {
			justify-content: center;
			width: 100%;
		}
	}
}
.banner-right {
	margin: -180px 0 -72px;
	position: relative;
	z-index: 9;
	&-text {
		background: #292194;
		border-radius: 20px 0 0 20px;
		padding: 27px 79px;
		width: 45%;
		margin-left: auto;
		display: flex;
		align-items: center;
		min-height: 258px;
		.content {
			color: var(--white);
			max-width: 416px;
		}
		@include breakpoint(max-lg) {
			padding: 34px 41px;
		}
	}
}
.what-we-do-section {
	padding: 82px 0 95px;
	position: relative;
	&-shape {
		clip-path: polygon(0 0, 50% 100%, 100% 0);
		width: 180px;
		height: 100px;
		bottom: -50px;
		transform: translateX(-50%);
		background: var(--section);
		position: absolute;
		left: 50%;
	}
	@include breakpoint(max-md) {
		padding-top: 57px;
		padding-bottom: 40px;
	}
}
.section-title {
	font-weight: 400;
	font-size: 58px;
	line-height: 69px;
	margin-bottom: 45px;
	text-transform: capitalize;
	@include breakpoint(max-md) {
		text-align: center;
		font-size: 43px;
		line-height: 54px;
	}
}
.what-we-do-card {
	border-radius: 20px;
	transition: all ease 0.3s;
	position: relative;
	&:not(:last-child) {
		&::after {
			content: "";
			top: 30px;
			bottom: 30px;
			width: 1px;
			background: #d7dce7;
			position: absolute;
			right: 0;
		}
	}
	.top {
		padding: 44px 42px 31px;
		border-bottom: 1px solid transparent;
		transition: all ease 0.3s;
		.icon {
			margin-bottom: 35px;
		}
		.subtitle {
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
		}
	}
	.bottom {
		padding: 25px 17px 34px 43px;
		font-weight: 400;
		font-size: 14px;
		line-height: 32px;
	}
	.radial {
		position: absolute;
		bottom: -90px;
		transform: translateX(-50%);
		left: 50%;
		z-index: -3;
		width: 305px;
		height: 305px;

		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(196, 201, 214, 0.5) 0%,
			rgba(196, 201, 214, 0) 100%
		);
		opacity: 0;
		transition: all ease 0.3s;
		visibility: hidden;
	}
	&:hover {
		transform: scale(100.4%);
		&::after,
		&::before {
			opacity: 0;
		}
		background: #ffffff;
		.top {
			border-color: #d7dce7;
		}
		rect[fill="white"] {
			fill: var(--section);
		}
		.radial {
			opacity: 1;
			transform: translateX(-50%);
			visibility: visible;
		}
	}
}
.what-we-do-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.what-we-do-card {
		width: calc(100% / 3);
		@include breakpoint(max-lg) {
			width: calc(100% / 2);
			&::after {
				display: none;
			}
		}
		@include breakpoint(max-md) {
			width: calc(100% / 1);
			&::after {
				display: none;
			}
		}
	}
}
.highlight-section {
	padding: 135px 0;
	@include breakpoint(max-lg) {
		padding: 101px 0 50px;
	}
}
.project-item {
	display: flex;
	align-items: center;
	gap: 65px;
	img {
		border-radius: 20px;
		object-fit: cover;
		width: 635px;
	}
	.cont {
		width: 0;
		flex-grow: 1;
		.subtitle {
			font-weight: 400;
			font-size: 36px;
			line-height: 43px;
			text-transform: capitalize;
			color: #3f3f3f;
			margin-bottom: 22px;
		}
		p {
			font-size: 14px;
			margin-bottom: 13px;
		}
		.cmn-btn {
			min-width: 184px;
		}
	}
	@include breakpoint(max-xl) {
		gap: 30px;
		img {
			width: 460px;
		}
	}
	@include breakpoint(max-lg) {
		img {
			width: 360px;
		}
		.cont {
			@include breakpoint(md) {
				.subtitle {
					font-size: 24px;
					line-height: 28px;
					margin-bottom: 15px;
				}
				p {
					line-height: 1.65;
				}
				.cmn-btn {
					min-width: 120px;
					padding-top: 8px;
					padding-bottom: 6px;
				}
			}
		}
	}
	@include breakpoint(max-xl) {
		flex-wrap: wrap;
		img {
			width: 100%;
		}
		.cont {
			width: 100%;
		}
	}
}
.project-highlight-wrapper {
	display: flex;
	flex-direction: column;
	gap: 161px;
	.project-item {
		&:nth-child(even) {
			flex-direction: row-reverse;
		}
		img {
		}
	}
	@include breakpoint(max-xl) {
		gap: 100px;
	}
	@include breakpoint(max-md) {
		gap: 50px;
	}
}
.technologies-section {
	padding: 92px 0;
	@include breakpoint(max-md) {
		padding: 50px 0;
	}
}
.tech-item {
	padding: 39px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	transition: all ease 0.3s;
	&:hover {
		background: var(--white);
		box-shadow: 0 15px 30px rgba(196, 201, 214, 1);
	}
	img {
		max-width: 100%;
	}
	border: 1px solid #d7dce6;
	border-radius: 20px;
	@include breakpoint(max-lg) {
		padding: 25px;
	}
}
footer {
	background: var(--dark);
	overflow: hidden;
}
.contact-area {
	padding: 95px 0;
	.section-title {
		color: var(--white);
		margin-bottom: 35px;
	}
	.form-control {
		background: transparent;
		outline: none;
		border: none;
		box-shadow: none;
		border-bottom: 1px solid #524ca9;
		color: var(--white);
		border-radius: 0;
		resize: none;
		font-weight: 400;
		font-size: 14px;
		line-height: 12px;
		&::placeholder {
			color: var(--white);
		}
		&:where(textarea) {
			height: 100px;
		}
	}
	.cmn-btn {
		background: transparent;
		color: var(--white);
		min-width: 184px;
	}
	@include breakpoint(max-lg) {
		.section-title {
			font-size: 36px;
			margin-bottom: 20px;
		}
	}
	@include breakpoint(max-md) {
		padding-top: 0;
		padding-bottom: 60px;
		.section-title {
			text-align: left;
			margin-bottom: 14px;
		}
	}
}
.globe {
	@include breakpoint(max-lg) {
		img {
			max-width: 500px;
		}
	}
	@include breakpoint(max-md) {
		padding-top: 60px;
		img {
			width: 100%;
			max-width: 450px;
			margin: 0 auto;
		}
	}
}
.footer-top-wrapper {
	padding: 103px 86px;
	background: #282092;
	border-radius: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.logo-area {
		max-width: 416px;
		color: var(--white);
		text-transform: capitalize;
		.logo {
			display: block;
			max-width: 226px;
			margin-bottom: 45px;
			img {
				width: 100%;
			}
		}
		@include breakpoint(max-lg) {
			margin-left: auto;
			margin-right: auto;
			.logo {
				margin-bottom: 20px;
			}
		}
	}
	@include breakpoint(max-lg) {
		padding: 42px 24px;
	}
	@include breakpoint(max-lg) {
		padding: 42px 24px;
		text-align: center;
		font-size: 15px;
		.logo {
			margin-left: auto;
			margin-right: auto;
		}
	}
	@include breakpoint(max-lg) {
		.social-follow {
			margin: 0 auto;
		}
	}
}
.footer-bottom {
	padding: 40px 0;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 32px;
	color: var(--white);
	@include breakpoint(lg) {
		padding: 36px 0 48px;
	}
}
.seperate-line {
	height: 6px;
	background: radial-gradient(
			123.9% 1139.41% at -1.97% 100%,
			#f8bc6a 0%,
			#15ecca 35%,
			#227afc 70%,
			#b432e9 100%
		)
		/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	border-radius: 40px;
}
